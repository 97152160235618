// packages
import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo } from "react";

// components
import { FormField } from "@/shared/components/FormField";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import { IconButton } from "@/shared/components/ui/IconButton";
import { Label } from "@/shared/components/ui/label";
import { Select } from "@/shared/components/ui/Select";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { Combobox } from "@/shared/components/ui/Combobox";
import { Badge } from "@/shared/components/ui/Badge";
import { Checkbox } from "@/shared/components/ui/checkbox";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// utils
import Strings from "@/shared/utils/Strings.constants";
import { PolicyUtils } from "@/shared/utils/PolicyUtils";
import { cn } from "@/shared/utils";

// media
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faDog, faCat, faVenus, faMars } from "@fortawesome/pro-solid-svg-icons";

// types
import { PetBreed } from "spot-types/entities/PetBreed";
import { Species } from "spot-types/entities/Species";
import { Quote } from "../types/Quote.interface";

interface PetInfoConfigProps {
    topBreeds: string[];
    policyIndex: number;
    petBreeds?: PetBreed[];
    onDelete?: () => void;
    allowDelete?: boolean;
    showNoPetCheckbox?: boolean;
    showMicrochip?: boolean;
    // prop to show birthdate month & year dropdowns
    showBirthDate?: boolean;
    // prop to show the age dropdown
    showAge?: boolean;
    breedListHeight?: string;
    ageField?: {
        maxAgeCat?: number;
        maxAgeDog?: number;
        maxAgeError?: string;
    };
    styles?: {
        wrapper?: string;
        fields?: {
            name?: string;
            age?: string;
            birthdate?: string;
            species?: string;
            breed?: string;
            gender?: string;
            microchip?: string;
            noPet?: string;
        };
    };
    containBreedDropdown?: boolean;
}

export function PetInfoEditor(props: PetInfoConfigProps) {
    const {
        policyIndex,
        petBreeds,
        topBreeds,
        showMicrochip,
        showBirthDate,
        showNoPetCheckbox,
        showAge,
        breedListHeight,
        ageField,
        allowDelete,
        onDelete,
        styles,
        containBreedDropdown = false
    } = props;

    const petBreedsLoading = !petBreeds;

    const { control, watch, setValue, getValues, setError, clearErrors, resetField } = useFormContext<Partial<Quote>>();

    const ages = PolicyUtils.getAges();
    const months = PolicyUtils.getMonths();
    const years = PolicyUtils.getYears();
    const isQuoteUpdating = useAppLayerContext().appState?.isQuoteUpdating;
    const currentPet = watch(`policies.${policyIndex}`);
    const petSpeciesText = currentPet.species?.toLowerCase() ?? "pet";
    const availableBreeds = useMemo<{ value: string; label: string }[]>(() => {
        if (!currentPet.species || !petBreeds) {
            return [];
        }

        return petBreeds
            .filter(breed => breed.species === currentPet.species)
            .map(breed => ({
                value: breed.id,
                label: breed.name
            }));
    }, [petBreeds, currentPet.species]);

    const formatBreeds = useCallback(
        (breeds: { value: string; label: string }[]) => {
            const topBreedsSet = new Set(topBreeds);
            const topFiveFiltered: typeof breeds = [];
            const remainingBreeds: typeof breeds = [];

            for (const breed of breeds) {
                if (topBreedsSet.has(breed.label)) {
                    topFiveFiltered.push(breed);
                } else {
                    remainingBreeds.push(breed);
                }
            }

            remainingBreeds.sort((a, b) => a.label.localeCompare(b.label));
            return [...topFiveFiltered, ...remainingBreeds];
        },
        [topBreeds]
    );

    const sortedBreeds = useMemo(() => {
        return formatBreeds(availableBreeds);
    }, [availableBreeds, formatBreeds]);

    const handleAgeChange = (value: string) => {
        const newAge = Number(value);
        setValue(`policies.${policyIndex}.age`, newAge, { shouldDirty: true, shouldValidate: true, shouldTouch: true });

        if (ageField?.maxAgeCat && ageField?.maxAgeDog) {
            const currentSpecies = getValues(`policies.${policyIndex}.species`);
            const maxAge = currentSpecies === "Cat" ? ageField?.maxAgeCat : ageField?.maxAgeDog;
            const hasAgeError = newAge >= maxAge;

            if (hasAgeError && ageField?.maxAgeError && !!currentSpecies) {
                setError(`policies.${policyIndex}.age`, {
                    type: "manual",
                    message: ageField.maxAgeError.replace("%{age}", `${maxAge}`).replace("%{species}", currentSpecies.toLowerCase())
                });
            } else {
                clearErrors(`policies.${policyIndex}.age`);
            }
        }
    };

    const handleYearChange = (value: string) => {
        setValue(`policies.${policyIndex}.birthYear`, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
        clearErrors(`policies.${policyIndex}.birthYear`);
    };

    const handleMonthChange = (value: string) => {
        setValue(`policies.${policyIndex}.birthMonth`, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
        clearErrors(`policies.${policyIndex}.birthMonth`);
    };

    const handleNoPetChecked = (isChecked: boolean) => {
        const currentPetName = getValues(`policies.${policyIndex}.name`);

        if (isChecked) {
            setValue(`policies.${policyIndex}.extra.noPetChecked`, true, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
            if (!currentPetName) {
                setValue(`policies.${policyIndex}.name`, Strings.TEMP_PET_NAME, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
            }
        } else {
            setValue(`policies.${policyIndex}.extra.noPetChecked`, false, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
            if (currentPetName === Strings.TEMP_PET_NAME) {
                resetField(`policies.${policyIndex}.name`, { defaultValue: "" });
            }
        }
    };

    return (
        <>
            {!!currentPet && (
                <>
                    {allowDelete && (
                        <div className="flex items-center justify-end">
                            <IconButton title="Remove Pet" size="petInfo" onClick={onDelete} disabled={isQuoteUpdating}>
                                <FontAwesomeIcon icon={faCircleXmark} className="text-content-secondary" />
                            </IconButton>
                        </div>
                    )}
                    <div className={cn("grid gap-4", styles?.wrapper)}>
                        <Controller
                            name={`policies.${policyIndex}.name`}
                            control={control}
                            render={({ field: { ref, ...rest }, fieldState }) => (
                                <FormField className={cn(styles?.fields?.name)} error={fieldState.error?.message} errorId={`error-pet-name-${policyIndex}`}>
                                    <InputWithLabel
                                        variant="floating"
                                        id={`policies.${policyIndex}.name`}
                                        label={Strings.PET_NAME}
                                        inputRef={ref}
                                        error={fieldState.error?.message}
                                        inputProps={{
                                            ...rest,
                                            "aria-describedby": fieldState.error ? `error-pet-name-${policyIndex}` : undefined
                                        }}
                                    />
                                </FormField>
                            )}
                        />
                        {showNoPetCheckbox && (
                            <Controller
                                name={`policies.${policyIndex}.extra.noPetChecked`}
                                control={control}
                                render={({ field: { ref, value } }) => (
                                    <>
                                        <FormField className={cn(styles?.fields?.noPet, "flex-row gap-2")}>
                                            <Checkbox
                                                id={`no-pet-checkbox-${policyIndex}`}
                                                aria-labelledby={`no-pet-checkbox-label-${policyIndex}`}
                                                checked={value}
                                                ref={ref}
                                                onCheckedChange={handleNoPetChecked}
                                            />
                                            <Label
                                                id={`no-pet-checkbox-label-${policyIndex}`}
                                                htmlFor={`no-pet-checkbox-${policyIndex}`}
                                                className="flex flex-col bg-background-transparent text-xs text-content-secondary"
                                            >
                                                <span className="text-sm text-content-primary">{Strings.NO_PET_NAME}</span>
                                            </Label>
                                        </FormField>
                                    </>
                                )}
                            />
                        )}
                        {showAge && (
                            <Controller
                                name={`policies.${policyIndex}.age`}
                                control={control}
                                render={({ field: { ref, onChange, ...rest }, fieldState }) => (
                                    <FormField className={cn(styles?.fields?.age)} error={fieldState.error?.message} errorId={`error-pet-age-${policyIndex}`}>
                                        <Label id={`policies.${policyIndex}.age-label`} variant="floating" size="xs" htmlFor={`policies.${policyIndex}.age`}>
                                            {typeof currentPet.age === "number" && Strings.PET_AGE}
                                        </Label>
                                        <Select
                                            ref={ref}
                                            {...rest}
                                            value={typeof currentPet.age === "number" ? String(currentPet.age) : undefined}
                                            onValueChange={handleAgeChange}
                                            placeholder={Strings.PET_AGE}
                                            options={ages}
                                            className="flex-1"
                                            error={fieldState.error?.message}
                                            triggerProps={{
                                                id: `policies.${policyIndex}.age`,
                                                // aria-label is only needed when placeholder is present, otherwise use label value via aria-labelledby
                                                "aria-label": typeof currentPet.age !== "number" ? Strings.PET_AGE : undefined,
                                                "aria-labelledby": `policies.${policyIndex}.age-label`,
                                                "aria-describedby": fieldState.error ? `error-pet-age-${policyIndex}` : undefined
                                            }}
                                        />
                                    </FormField>
                                )}
                            />
                        )}

                        {showBirthDate && (
                            <div className={cn("flex gap-2", styles?.fields?.birthdate)}>
                                <Controller
                                    name={`policies.${policyIndex}.birthMonth`}
                                    control={control}
                                    render={({ field: { ref, onChange, ...rest }, fieldState }) => (
                                        <FormField className="flex-1" error={fieldState.error?.message} errorId={`error-pet-birthMonth-${policyIndex}`}>
                                            <Label id={`policies.${policyIndex}.birthMonth-label`} variant="floating" size="xs" htmlFor={`policies.${policyIndex}.birthMonth`}>
                                                {typeof currentPet.birthMonth === "string" && currentPet.birthMonth !== "" && Strings.SELECT_MONTH}
                                            </Label>
                                            <Select
                                                ref={ref}
                                                {...rest}
                                                value={currentPet.birthMonth ? String(currentPet.birthMonth) : undefined}
                                                onValueChange={handleMonthChange}
                                                placeholder={Strings.SELECT_MONTH}
                                                options={months}
                                                error={fieldState.error?.message}
                                                triggerProps={{
                                                    id: `policies.${policyIndex}.birthMonth`,
                                                    "aria-label": "Select Birth Month",
                                                    "aria-labelledby": `policies.${policyIndex}.birthMonth-label`,
                                                    "aria-describedby": fieldState.error ? `error-pet-birthMonth-${policyIndex}` : undefined
                                                }}
                                            />
                                        </FormField>
                                    )}
                                />

                                <Controller
                                    name={`policies.${policyIndex}.birthYear`}
                                    control={control}
                                    render={({ field: { ref, onChange, ...rest }, fieldState }) => (
                                        <FormField className="flex-1" error={fieldState.error?.message} errorId={`error-pet-birthYear-${policyIndex}`}>
                                            <Label id={`policies.${policyIndex}.birthYear-label`} variant="floating" size="xs" htmlFor={`policies.${policyIndex}.birthYear`}>
                                                {typeof currentPet.birthYear === "string" && currentPet.birthYear !== "" && Strings.SELECT_YEAR}
                                            </Label>
                                            <Select
                                                ref={ref}
                                                {...rest}
                                                value={currentPet.birthYear ? String(currentPet.birthYear) : undefined}
                                                onValueChange={handleYearChange}
                                                placeholder={Strings.SELECT_YEAR}
                                                options={years}
                                                error={fieldState.error?.message}
                                                triggerProps={{
                                                    id: `policies.${policyIndex}.birthYear`,
                                                    "aria-label": "Select Birth Year",
                                                    "aria-labelledby": `policies.${policyIndex}.birthYear-label`,
                                                    "aria-describedby": fieldState.error ? `error-pet-birthYear-${policyIndex}` : undefined
                                                }}
                                            />
                                        </FormField>
                                    )}
                                />
                            </div>
                        )}

                        <Controller
                            name={`policies.${policyIndex}.species`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <FormField error={fieldState.error?.message} className={cn(styles?.fields?.species)}>
                                    <RadioButtonGroup
                                        initialValue={field.value}
                                        onValueChange={value => {
                                            field.onChange(value);
                                            setValue(`policies.${policyIndex}.breedID`, ``);
                                        }}
                                        error={fieldState.error?.message}
                                        options={[
                                            { value: Species.Dog, label: "Dog", icon: <FontAwesomeIcon icon={faDog} size="lg" /> },
                                            { value: Species.Cat, label: "Cat", icon: <FontAwesomeIcon icon={faCat} size="lg" /> }
                                        ]}
                                    />
                                </FormField>
                            )}
                        />

                        <Controller
                            name={`policies.${policyIndex}.gender`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <FormField error={fieldState.error?.message} className={cn(styles?.fields?.gender)}>
                                    <RadioButtonGroup
                                        initialValue={field.value}
                                        onValueChange={field.onChange}
                                        error={fieldState.error?.message}
                                        options={[
                                            {
                                                value: "F",
                                                label: "Female",
                                                icon: <FontAwesomeIcon icon={faVenus} size="lg" />
                                            },
                                            {
                                                value: "M",
                                                label: "Male",
                                                icon: <FontAwesomeIcon icon={faMars} size="lg" />
                                            }
                                        ]}
                                    />
                                </FormField>
                            )}
                        />

                        <Controller
                            name={`policies.${policyIndex}.breedID`}
                            control={control}
                            render={({ field: { value, onChange, ref, ...rest }, fieldState }) => (
                                <FormField className={cn(styles?.fields?.breed)} error={fieldState.error?.message} errorId={`error-pet-breed-${policyIndex}`}>
                                    <Label id={`policies.${policyIndex}.breed-label`} variant="floating" size="xs" htmlFor={`policies.${policyIndex}.breed`}>
                                        {!!currentPet.breedID && Strings.SELECT_BREED}
                                    </Label>
                                    <Combobox
                                        ref={ref}
                                        withContainer={containBreedDropdown}
                                        value={value}
                                        keyByLabel
                                        placeholder={Strings.SELECT_BREED}
                                        options={sortedBreeds}
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        isLoading={petBreedsLoading}
                                        error={fieldState.error?.message}
                                        height={breedListHeight}
                                        triggerProps={{
                                            id: `policies.${policyIndex}.breed`,
                                            "aria-labelledby": `policies.${policyIndex}.breed-label`,
                                            "aria-label": !currentPet.breedID ? Strings.SELECT_BREED : undefined
                                        }}
                                    />
                                </FormField>
                            )}
                        />
                        {showMicrochip && (
                            <Controller
                                name={`policies.${policyIndex}.extra.microchipID`}
                                control={control}
                                render={({ field: { ref, ...rest }, fieldState }) => (
                                    <FormField
                                        className={cn(styles?.fields?.microchip)}
                                        error={fieldState.error?.message}
                                        hint={<Badge variant={"secondary"}>Enter chip number for a 5% discount</Badge>}
                                    >
                                        <InputWithLabel
                                            variant="floating"
                                            id={`policies.${policyIndex}.extra.microchipID`}
                                            label={`Your ${petSpeciesText}'s microchip number`}
                                            inputRef={ref}
                                            error={fieldState.error?.message}
                                            inputProps={{
                                                ...rest
                                            }}
                                        />
                                    </FormField>
                                )}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
